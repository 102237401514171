@import '../../assets/styles/tools/vars';

.header {
    &--blue {
        .navbar-nav {
            a {
                color: $blue;
                &:last-child {
                    color: $mainColor;
                    background-color: $blue;
                    width: 120px;
                    padding: 8px 14px;
                    text-align: center;
                    border-radius: 11px;
                    transition: all 0.3s ease-out;
                    border: 2px solid $blue;

                    &:hover {
                        border: 2px solid $mainColor;
                        background: $mainColor;
                        color: $blue;
                    }
                }
            }
        }

        .link_animation::before {
            background-color: $blue;
        }
    }
}