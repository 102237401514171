@charset "utf-8";
* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    // list-style: none;
    text-decoration: none;
}

a {
    text-decoration: none;
}

html {
    font-size: 16px;
}

body {
    min-width: 320px;
    font-family: 'Montserrat', sans-serif;
}

html,
body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.site-wrap {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
}

@import "tools/vars";
@import "tools/animation";
@import "tools/main";
@import "tools/media";
@import "tools/utils";