.mainBanner {
    display: flex;
    color: #fff;
    height: 90vh;
    width: 100%;
    flex-direction: column;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/MainBanner.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    p {
        font-weight: 700;
        margin-top: 20px;
    }

    a {
        color: #fff;
    }

    .col-md-12 {
        margin-top: 5em;
        padding-left: 140px;
    }
}