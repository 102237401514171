@import './vars';

.mainSlideOut{
    animation-name: mainSlideOut;
    animation-duration:0s;
    animation-delay:0s;
    animation-iteration-count:1;
}
@keyframes mainSlideOut{
    from{
        transform: translateY(0);
        opacity: 1;
    }
    to{
        transform: translateY(80px);
        opacity: 0;
    }
}

.link_animation {
    position: relative;
    &::before {
        width: 0;
        content: "";
        background-color: $mainColor;
        height: 3px;
        position: absolute;
        bottom: 0;
        transition: width 0.3s ease-out;
        left: 0;
    }
    &:hover::before {
        width: 100%;
    }

    &_arrow {
        span {
            display: inline-block;
            transition: transform 0.3s ease-out;
        }
        &:hover span {
            transform: translateX(30px);
        }
    }
}

.amination_rotate {
    
    svg {
        transition: transform 0.3s ease-out;
    }

    &:hover svg {
        transform: rotate(360deg);
    }
}

.animation_image {
    position: relative;
    
    &--next {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        transition: opacity 0.3s ease-out;
    }

    &:hover &--next {
        opacity: 1;
    }
}
