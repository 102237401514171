@media only screen and (max-width:1440px) {}

@media only screen and (max-width:1200px) {
    .header__container .col-md-12 {
        margin-top: 5em;
        // padding-left: 140px;
    }
    .first-content>.row {
        margin: 80px 30px 0;
        padding-left: 60px;
    }
    .first-content .drop-shadow {
        // -webkit-box-shadow: 0px 18px 94px rgba(0, 0, 0, 0.25);
        // box-shadow: 0px 18px 94px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 18px 34px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 18px 34px rgba(0, 0, 0, 0.15);
        font-size: 12px;
        border-radius: 15px;
        margin: 15px;
    }
    .container__indent {
        padding: 0 100px;
    }
    footer p a {
        font-size: 26px;
    }
    .conteiner__projects-text p {
        margin-bottom: 40px;
    }
    .projects-page .footer {
        padding-top: 0;
    }
}

@media only screen and (max-width:1024px) {
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
    }
    .header__container .col-md-12 {
        margin-top: 6em;
        // padding-left: 140px;
    }
    .slider-wrapper {
        width: calc(100% + 30px);
        padding: 0 15px;
        margin-left: -15px;
        overflow: hidden;
    }
    .container__indent {
        padding: 0 80px;
    }
    .slick-list,
    .slider__onMobile,
    .slider__team {
        display: block;
        overflow: hidden;
    }
    .first-content .slider-wrapper {
        padding: 80px 15px 65px;
    }
    .slider__onMobile .drop-shadow {
        padding: 30px;
    }
    .first-content .row {
        display: none;
    }
    .slider__itemMobile img {
        height: 44px;
        margin-bottom: 30px;
    }
    .slick-dots {
        margin: 50px 0;
    }
    .works {
        margin-top: 0;
    }
    footer p a {
        font-size: 25px;
    }
}

@media (min-width: 768px) and (max-width: 1100px) {
    body {
        font-size: 14px;
    }
    .top-header {
        margin: 40px 0;
    }
    .nav-bar {
        width: 100%;
        background: transparent;
    }
    .nav-bar a {
        margin: 0.5em;
    }
    .container {
        max-width: 100%;
    }
    h3 {
        line-height: 1;
    }
    .works p {
        font-size: 1rem;
    }
    .submit__rounded.submit {
        height: auto;
        margin: 0;
        padding: 10px;
    }
    .about p {
        font-size: 1rem;
    }
    .hire-us__container .col-md-2 {
        margin-top: 0;
    }
}

@media only screen and (max-width:991px) {}

@media only screen and (max-width:769px) and (width: 768px) {}

// @media only screen and (max-width:576px) {}
@media (max-width: 768px) {
    .container__indent {
        padding: 0 30px;
    }
    .header__container {
        background-image: url("../../img/bg-mob.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .header__container .col-md-12 {
        padding-left: 30px;
    }
    .top-header {
        margin: 25px 0;
    }
    .top-header~.container {
        margin: 80px 0 20px;
        padding: 0 25px;
    }
    .logo {
        width: 70%;
    }
    .logo img {
        display: none;
    }
    .logo img:last-child {
        margin-left: 1rem;
    }
    .logo .logo-mob {
        display: block;
    }
    .navbar-toggler {
        padding: 0px 24px;
    }
    .nav-bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        background: #222d;
        padding: 2em;
    }
    .nav-bar a {
        padding: 1em 1.3em;
    }
    .nav-bar a:last-child {
        line-height: 0.6;
    }
    .submit__rounded.submit {
        width: 100%;
        height: 42px;
        margin: 0 auto;
        line-height: 0;
    }
    .social-menu .social-links {
        margin-top: 0;
        position: absolute;
        padding-left: 30px;
        bottom: 15px;
    }
    .btn-scroll-down {
        position: absolute;
        bottom: 25px;
        right: 33px;
        width: 46px;
    }
    h1 {
        font-size: 31px;
    }
    h2 {
        line-height: 1;
        font-size: 31px;
        margin: 25px auto;
        width: 285px;
        text-align: center;
    }
    h3 {
        font-size: 20px;
        line-height: 1;
    }
    h5 {
        line-height: 2;
        font-size: 14px;
        text-align: center;
    }
    .services .col-md-6 {
        width: 90%;
        margin: auto;
    }
    .services__block h3 {
        line-height: 2;
    }
    .col-md-2 h3 {
        width: 30px;
        height: 30px;
    }
    .first-content.container>.row {
        display: none;
    }
    .slider,
    .slider-dots {
        display: block;
    }
    .col.mx-1 p {
        font-size: 1em;
    }
    .col.mx-1 {
        -webkit-box-shadow: 0px 18px 94px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 18px 94px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 1em;
        width: 220px;
        height: 270px;
        margin: 0 auto!important;
    }
    .works .col-md-6 {
        height: auto;
        padding: 2.5px!important;
    }
    .works p {
        font-size: 1em;
    }
    .form-btn.projects {
        padding: 10px 60px;
    }
    .about p {
        font-size: 1.1em;
    }
    .services {
        margin: 0 auto 40px;
    }
    /* .services h3 {
        line-height: 2;
    } */
    .hire-us__container .col-md-2 {
        margin-top: -25px;
    }
    .hire-us .form {
        height: 42px;
    }
    .hire-us h2 {
        width: 90%;
        margin: auto;
    }
    .hire-us .form {
        border: 1px solid #000;
        margin: 5px 0;
        border-bottom-left-radius: 0.25rem!important;
        border-top-left-radius: 0.25rem!important;
        border-bottom-right-radius: 0.25rem!important;
        border-top-right-radius: 0.25rem!important;
        // text-align: center;
    }
    .hire-us .form {
        width: 100%;
    }
    .hire-us__container.form__rounded .row {
        margin: 0;
    }
    .hire-us h5 {
        padding-top: 40px;
    }
    .hire-us p {
        line-height: 3;
        padding: 15px 0 30px;
    }
    .btn-style {
        width: 90%;
    }
    .about {
        text-align: center;
    }
    .text-about {
        margin-bottom: 50px;
    }
    .about h2 {
        line-height: 2;
        margin: auto;
    }
    .about .col-md-6 {
        display: flex;
        flex-direction: column-reverse;
    }
    .about .row {
        display: flex;
        flex-direction: column-reverse;
    }
    .w-75 {
        width: 100%!important;
    }
    .row.w-75.m-auto {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 1em;
    }
    .w-75 .col-md-4 {
        display: flex;
        flex-direction: column;
        width: 30%;
    }
    .our-team .slider-wrapper {
        margin-left: -5px;
    }
    .our-team h2 {
        line-height: 0;
    }
    .carrer.container__indent {
        padding: 0 15px;
    }
    .carrer h5 {
        text-align: center;
    }
    .table>:not(caption)>*>* {
        padding: .5rem 1rem;
    }
    .table>:not(caption)>tr>td svg {
        float: right;
        margin-right: -10px;
    }
    td:nth-child(odd) {
        display: none;
    }
    .footer.container__width {
        padding-top: 0;
    }
    footer .col-md-9 div h5,
    footer h2 {
        text-align: center;
    }
    footer h2 {
        font-size: 1.6em;
        text-align: center;
    }
    .footer__text {
        margin-top: 30px;
    }
    .form-btn,
    .button__services {
        padding: 5px 7px;
        font-size: 0.9rem;
    }
    footer input,
    footer textarea {
        border: 1px solid #ccc;
        border-radius: 10px;
        line-height: 2;
        padding-left: 0.5em;
        width: 100%;
        margin-bottom: .5em;
    }
    footer textarea {
        height: 100px;
    }
    .form-btn.submit {
        line-height: 1;
        width: 100%;
        border-radius: 8px;
    }
    footer p a {
        font-size: 24px;
    }
    // vocation-page
    .logo .logo_mob {
        display: block;
    }
    .footer-vocation {
        margin-bottom: 60px;
        margin-top: 0px;
    }
    .vocation>p {
        text-align: center;
    }
    .footer-vocation .footer__text {
        margin-top: 40px;
    }
    .footer__text h5 {
        text-align: left;
        margin-bottom: 15px;
    }
    .footer__text~.social-links {
        margin-top: 50px;
    }
    .footer-vocation .form-btn.submit {
        width: 100%;
    }
    .conteiner__projects-text p {
        margin-bottom: 20px;
    }
    .projects-page section {
        margin: 0;
    }
}

@media (min-width: 320px) and (max-width: 400px) {
    body {
        font-size: 13px;
    }
    .nav-bar a:last-child {
        line-height: 1.3;
        text-align: center;
    }
    .col.mx-1 {
        padding: 2em;
    }
    .hire-us .form {
        // width: auto;
    }
    .about p {
        font-size: 1em;
    }
    .submit__rounded.submit {
        width: 100%;
    }
}