.maxW {
    &-500 {
        max-width: 500px;
    }

    &-180 {
        max-width: 180px;
    }
}

.mb {
    &-15 {
        margin-bottom: 15px;
    }
}

