* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    // outline: 1px solid red;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

body {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
}

.container__indent {
    padding: 0 120px;
}

header.row {
    position: relative;
}

.top-header {
    display: flex;
    margin: 40px;
    align-items: center;
}

header ul li {
    list-style: none;
}

.hire-us a {
    color: #fff;
}

.header__container a:hover,
.hire-us a:hover {
    color: #bdbcbc;
    text-decoration: none;
    transition: color 0.2s ease-out 0.2s;
}

h1 {
    font-size: 3em;
    font-weight: 700;
    line-height: 1.5;
}

.navbar-nav {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-collapse {
    justify-content: flex-end;
}

.navbar-nav a,
.scroll.js-mob-scroll {
    color: #fff;
    padding: 7px 14px;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
}

.navbar-nav a {
    margin-left: 1em;
}

.scroll.js-mob-scroll {
    padding: 1em 1.3em;
}

.navbar-nav a:last-child,
.scroll.js-mob-scroll a:last-child {
    color: #005dd9;
    background-color: #fff;
    width: 120px;
    padding: 8px 14px;
    text-align: center;
    border-radius: 11px;
    transition: all 0.3s ease-out;
    border: 2px solid #fff;
}

.navbar-nav a:last-child:hover {
    border: 2px solid #005dd9;
    background: #005dd9;
    color: #fff;
    transition: color 0.3s ease-out;
}

.logo {
    width: 50%;
}

.logo img:last-child {
    margin-left: 2rem;
}

.logo-mob {
    display: none;
}

.social-menu .social-links {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.social-links li {
    display: inline-flex;
    margin-left: 1em;
}

.btn-scroll-down {
    position: absolute;
    bottom: 50px;
    right: 70px;
    cursor: pointer;
}

section {
    margin: 60px 0;
}

.myBtn {
    display: inline-block;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #005dd9;
    opacity: 0.7;
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 11px;
    font-size: 18px;
}

.myBtn:hover {
    background-color: #005dd9;
    opacity: 1;
}
/* block with 5th columns*/

.first-content.container__indent {
    padding: 0;
    box-sizing: content-box;
}

.first-content > .row {
    margin: 80px 30px 0;
    padding-left: 100px;
}

.first-content .col {
    padding-right: 10px;
    padding-left: 10px;
}

.first-content h3 {
    line-height: 2.5;
}

.col.mx-1 img {
    height: 44px;
    margin-bottom: 30px;
}

.slider__onMobile {
    display: none;
}

.slider__item {
    outline: none;
    cursor: pointer;
}

h3 {
    font-size: 32px;
    line-height: 2;
    font-weight: 700;
}

.col p {
    font-size: 14px;
    line-height: 2;
}
/* block with works*/

h5 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 4px;
}

h2 {
    font-weight: 700;
    margin: 30px 0;
    line-height: 1;
    font-size: 64px;
}

.works {
    position: relative;
    z-index: 1;
}

.works h2,
.works h5 {
    text-align: center;
}

.works .col-md-6 {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 1.5em;
    overflow: hidden;
}

.image__zoom {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.image__zoom img {
    width: 100%;
    z-index: 1;
    transition: transform 1s;
    height: 400px;
    object-fit: cover;
}

.image__zoom img:hover {
    transform: scale(1.1);
}

.pos-absolute {
    pointer-events: none;
    position: absolute;
    z-index: 99;
    top: 30px;
    left: 30px;
    color: #fff;
    padding-right: 30px;
}

.works p {
    font-size: 1.2rem;
    // width: 95%;
}

.form-btn.projects {
    padding: 20px 100px;
    margin: 60px auto 80px;
    display: block;
    width: fit-content;
    transition: all 0.3s;
}

.form-btn.projects:hover,
.button__services:hover,
.submit:hover {
    color: #fff;
    background: #005dd9;
}
/*  services  */

.col-md-2 h3 {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    border: 2px solid #005dd9;
    color: #005dd9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-md-2 {
    padding-top: 1em;
}
/*  hire-us  */

.hire-us {
    background-color: #005dd9;
    text-align: center;
    color: #fff;
}

.hire-us .row {
    margin: 30px auto;
}

.hire-us h5 {
    line-height: 2;
    padding-top: 50px;
}

.hire-us p {
    line-height: 3;
    padding: 45px 0 60px;
}

.hire-us .col-md-4 {
    padding: 0;
}

.hire-us .form {
    width: 100%;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding-left: 0.5rem;
    height: 3.5em;
}

.hire-us__container {
    background-color: #fff;
    width: 90%;
    padding: 1em;
    margin: 60px auto;
    -webkit-box-shadow: 0 0 100px 5px #fff;
    box-shadow: 0 0 100px 5px #fff;
}

.hire-us__container .col-md-2 {
    padding-top: 27px;
    padding-left: 3px;
}

.form__rounded {
    border-radius: 24px;
}

.submit__rounded {
    border-radius: 11px;
    padding: 19px 0;
    height: auto;
}

.form.round-start {
    border-left: 2px solid #000;
    border-bottom-left-radius: 11px;
    border-top-left-radius: 11px;
}

.form.round-end {
    border-right: 2px solid #000;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
}

// .form.round-start, .form.round-end {}
.btn-style {
    background-color: #005dd9;
    // padding: 17px 64px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 700;
    border: none;
    margin-left: 6px;
    // height: 100%;
    width: 100%;
}
/* about */

.about {
    padding-top: 30px;
}

.about > .row {
    padding-top: 30px;
}

.about > .col-md-6 p {
    width: 80%;
}

.about p {
    font-size: 1.2em;
}

.about h3 {
    line-height: 1;
}
/* teams slider */

.slider__team .slick-slide img {
    width: 100%;
    object-fit: cover;
    height: 350px;
    font-size: 16px;
}

.slider-wrapper {
    width: 100%;
}

.our-team .slick-initialized .slick-slide {
    display: flex;
}

.slick-slide .slider__text {
    padding: 20px 0;
}

.slick-list {
    overflow: hidden;
}

.slick-dots {
    display: flex !important;
    justify-content: center;
    list-style: none;
}

.slick-dots li button {
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 7px;
    border: 1px solid #005dd9;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    background: transparent;
    font-size: 0px;
}

.slick-dots .slick-active button {
    background: #005dd9;
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 7px;
    border: 1px solid #005dd9;
    border-radius: 50%;
}

.slick-slide,
.slick-slide .drop-shadow {
    min-height: 100%;
}

.drop-shadow {
    margin: 10px;
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
}

.img-fluid {
    width: 100%;
    height: auto;
}

.our-team .slider-wrapper {
    padding: 10px 15px 20px;
}
/* carrer */

.carrer h5 {
    margin-bottom: 2rem;
}

.carrer svg {
    color: #005dd9;
}

.table-responsive {
    padding: 40px 0;
}

.table > tbody {
    border-top: 1px solid #dee2e6;
}

td:nth-child(2) {
    font-weight: 700;
}

tr {
    line-height: 2;
}

tr[data-href] {
    cursor: pointer;
}
/* footer */

.footer.container__width {
    padding-top: 90px;
}

footer .col-md-3 {
    font-size: 1.1em;
}

footer h2 {
    line-height: 1;
    font-size: 3em;
}

.footer__text h5 {
    margin-bottom: 25px;
}

.footer__text ~ .social-links {
    margin-top: 80px;
}

footer .social-links li:first-child {
    margin-left: 0;
}

footer p a {
    color: #000;
    font-weight: 500;
    font-size: 28px;
    white-space: pre;
    display: block;
}

footer > .social-links {
    margin-top: 3em;
}

.aside-text {
    font-size: 0.75rem;
}

h6 {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 2;
}

footer input,
footer textarea {
    border: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1.5em;
    width: 100%;
    font-weight: 700;
    color: #000;
    font-size: 24px;
    line-height: 3;
}

input:focus-visible,
textarea:focus-visible {
    border-bottom: 2px solid #005dd9;
    font-weight: normal;
    opacity: 0.8;
    outline: none;
}

footer input::placeholder,
footer textarea::placeholder {
    color: #000;
}

footer textarea {
    width: 83%;
    resize: none;
}

.form-btn,
.button__services {
    color: #005dd9;
    border: 1px solid #005dd9;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 700;
    background: none;
    margin-right: 5px;
    margin-bottom: 10px;
    transition: all 0.3s ease-out;
}

.form-btn.submit {
    padding: 15px 90px;
    font-size: 24px;
}

.footer__text {
    margin-top: 60px;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:hover .navbar-toggler:active {
    border: none;
    box-shadow: none;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("../../img/equal-sign.png");
}

// style for vocation page
.logo_mob {
    display: none;
}

.vocation-page .navbar-light .navbar-toggler {
    color: #005dd9 !important;
    border-color: #005dd9 !important;
}

.text-about {
    margin-bottom: 120px;
}

.vocation-page .navbar-nav a,
.projects-page .navbar-nav a {
    color: #005dd9;
    padding: 7px 18px;
    display: inline-block;
    font-weight: 700;
}

.vocation-page .navbar-nav a:last-child,
.projects-page .navbar-nav a:last-child {
    color: #fff;
    background: #005dd9;
}

.submit__rounded.submit {
    box-sizing: border-box;
    transition: all 0.3s;
    border: 2px solid #005dd9;
}
.submit__rounded.submit:hover {
    color: #005dd9;
    background: #fff;
    border: 2px solid #005dd9;
}

.vocation-page .navbar-light .navbar-toggler-icon {
    background-image: url("../../img/gamburger-vocation.png");
}

.responsibilities {
    margin-top: 30px;
    padding-left: 40px;
    list-style-image: url("../../img/ellipses.svg");
}

.responsibilities li {
    padding-left: 15px;
    margin-bottom: 35px;
}

.footer-vocation {
    margin-bottom: 100px;
    margin-top: 100px;
}

.footer-vocation .col-md-9 {
    display: flex;
    align-items: center;
}

.footer-vocation .footer__text {
    margin-top: 0;
}

.footer-vocation h5 {
    margin-bottom: 1.4em;
}

// style for projects-page
.conteiner__progects .col-md-4 {
    position: relative;
    overflow: hidden;
    margin-bottom: 1.5em;
}

.projects-page .social-links {
    margin-top: 40px;
}

.projects-page .social-links li {
    margin-bottom: 15px;
}

.conteiner__progects .works {
    margin-top: 0;
}

.conteiner__projects-text h2,
.conteiner__projects-text p {
    text-align: left;
}

.conteiner__projects-text p {
    font-weight: 700;
    margin-top: 20px;
}

.conteiner__projects-text a {
    color: #000;
}

.pos-absolute h3 {
    line-height: 1;
    margin-bottom: 10px;
}

.projects-page .footer {
    padding-top: 40px;
}
/* valid form*/

.error {
    color: red;
    display: none;
    font-size: 0.8em;
    margin-top: 5px;
    margin-left: 1.5em;
    position: absolute;
}

.error.visible {
    display: block;
}

html {
    overflow-x: hidden;
}

//mobile menu
.header__menu {
    width: 30px;
    height: 19px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    position: relative;
    z-index: 99999;
    margin-right: 2em;
}

.header__menu,
.header__menu span {
    display: block;
    transform: rotate(0deg);
    z-index: 3;
}

.header__menu span {
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
    opacity: 1;
    left: 0;
    transition: 0.25s ease-in-out;
}

.vocation-page .header__menu span,
.projects-page .header__menu span {
    background-color: #005dd9;
}

.open.header__menu span {
    background-color: #fff;
}

.header__menu,
.header__menu span {
    display: block;
    transform: rotate(0deg);
    z-index: 15;
}

.header-menu {
    background-color: #b1b1b1;
    height: 100%;
    max-width: 1204px;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    position: fixed;
    right: -1204px;
    top: 0;
    z-index: 9;
}

.header-menu.open {
    right: 0;
}

.header-menu ul {
    margin: 0;
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.header__menu span:first-child {
    top: 0;
}

.header__menu span:nth-child(2) {
    top: 8px;
}

.header__menu span:nth-child(3) {
    top: 16px;
}

.header__menu.open span:first-child {
    top: 8px;
    transform: rotate(135deg);
}

.header__menu.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.header__menu.open span:nth-child(3) {
    top: 8px;
    transform: rotate(-135deg);
}
